.navbar-container{
    display: flex;
    padding: 1rem 1.5rem;
    justify-content: flex-end;
    background-color: rgb(228, 228, 228);
    align-items: center;
}
.navbar-container > *{
    margin: 0 1.25rem;
    text-decoration: none;
    font-weight: 600;
    color:rgb(0, 99, 230);
}
.navbar-home{
    color:rgb(163, 162, 162);
}
.navbar-login {
    color:rgb(163, 162, 162);
}
.navbar-register{
    color:rgb(255, 255, 255);
    background-color: rgb(150, 150, 150);
    padding:0.35rem;
    border-radius: 6px;
}
.navbar-user{
    color: rgb(0, 154, 165);
}
.navbar-logout {
    color:rgb(156, 156, 156);
    padding:0.35rem;
}