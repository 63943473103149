.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 500px;
  margin: 50px auto;
  padding: 10px;
  border-radius: 8px;
}

.App h1{
  text-align: center;
}

.form{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
}

.form input[type="text"]{
  padding: 10px;
  width: 85%;
  border: 1px solid #eee;
  border-radius: 6px;
}

.form input[type="text"]:focus{
  outline: none;
}
.form button[type="submit"]:focus{
  outline: none;
}

.form button[type="submit"]{
  background-color: #008080;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
}

.todo-listItems{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.todo-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.todo-item button{
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 8px;
}
.todo-item button:focus{
  outline: none;
}
 
.updateItem{
  background-color: goldenrod;
  color: #fff;
}
.deleteItem{
  background-color: red;
  color: #fff;
}
.item-Content{
  width: 60%;
}
.update-form{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.update-new-input{
  width: 80%;
  border-radius: 6px;
  border: 1px solid #eee;
  padding: 8px;
  outline: none;
}
.update-new-btn{
  background-color: darkorchid;
  color: #fff;
  cursor: pointer;
}

.registerbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}
/* Full-width input fields */
input[type=text], input[type=password] {
  width: 85%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}

input[type=text]:focus, input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}
.signin {
  background-color: #f1f1f1;
  text-align: center;
}
.registerbtn:hover {
  opacity: 1;
}

.lb{
  margin-left: 20px;
}

a {
  color: dodgerblue;
}